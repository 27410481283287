import PropTypes from 'prop-types';

const propTypes = {
	value: PropTypes.shape({
		href: PropTypes.string,
		anchor: PropTypes.string,
		class: PropTypes.string,
		linktype: PropTypes.string,
		title: PropTypes.string,
		text: PropTypes.string,
		querystring: PropTypes.string,
		target: PropTypes.string,
		id: PropTypes.string,
	}),
};

export default propTypes;
