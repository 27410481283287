const defaultProps = {
	value: {
		src: '',
		alt: '',
		width: '',
		height: '',
	},
};

export default defaultProps;
