import React from 'react';
import {Text, RichText} from '@sitecore-jss/sitecore-jss-react';
import {DefaultProps, PropTypes} from '../../../core/Utils/SitecoreFields';

class TabbedContent extends React.Component {
	constructor(props) {
		super(props);
		const {fields, savedTab} = this.props;
		const {tabItems, canHide} = fields;

		const savedIndex = savedTab > tabItems.length ? 0 : savedTab;

		this.state = {
			tabArray: tabItems.map((item, index) => {
				return {
					isActive: !canHide.value && index === savedIndex,
					fields: item.fields,
				};
			}),
			activeContent: (canHide.value || !tabItems.length)
				? null
				: {
					field: tabItems[savedIndex].fields.description,
					index: savedIndex,
				},
		};
	}

	handleClick = index => {
		const {fields, handleActiveIndex = null} = this.props;
		const {canHide} = fields;

		this.setState(prevState => {
			let newContent = null;
			const newArray = prevState.tabArray.map((item, i) => {
				const active = i === index && (!item.isActive || !canHide.value);
				if (active) {
					newContent = {
						field: item.fields.description,
						index: index,
					};

					if (handleActiveIndex) {
						handleActiveIndex(index);
					}
				}
				return {
					...item,
					isActive: active,
				};
			});
			return {
				tabArray: newArray,
				activeContent: newContent,
			};
		});
	};

	hideAll = () => {
		this.setState(prevState => {
			return {
				tabArray: prevState.tabArray.map(tab => {
					return {
						...tab,
						isActive: false,
					};
				}),
				activeContent: null,
			};
		});
	};

	render() {
		const {heading, canHide, hideText} = this.props.fields;
		const {tabArray, activeContent} = this.state;

		return (
			<div className={`tabbed-content ${canHide.value ? 'can-collapse' : ''}`}>
				<div className={this.props.noPadding ? '' : 'l-padding'}>
					<Text field={heading} tag="h2" />
					<div className="tab-items">
						{tabArray.map((item, index) => (
							<button
								type="button"
								className={`tab ${index} ${
									item.isActive ? 'active' : ''
								}`}
								aria-expanded={item.isActive}
								disabled={item.isActive && !canHide.value}
								aria-disabled={item.isActive && !canHide.value}
								tabIndex="0"
								key={item.id}
								id={`tab-${index}`}
								onClick={() => this.handleClick(index)}
								aria-label={item.fields.ariaLabel.value}
							>
								<Text className="tab-header-link" field={item.fields.title} />
							</button>
						))}
					</div>

					{activeContent && (
						<div className="tab-item-description">
							<div
								id={'tab-panel'}
								role="region"
								className="tab-panel"
								aria-labelledby={`tab-${activeContent.index}`}
							>
								<RichText field={activeContent.field} />
							</div>
							{canHide.value && (
								<button className="hide-button" onClick={this.hideAll} type="button">
									<Text field={hideText} />
								</button>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

TabbedContent.propTypes = PropTypes.SitecoreItem.inject({
	heading: PropTypes.SingleLineText,
	canHide: PropTypes.Checkbox,
	tabItems: PropTypes.Multilist.inject(PropTypes.ContentBlock),
	hideText: PropTypes.SingleLineText,
});

TabbedContent.defaultProps = DefaultProps.SitecoreItem.inject({
	heading: DefaultProps.SingleLineText,
	canHide: DefaultProps.Checkbox,
	tabItems: DefaultProps.Multilist,
	hideText: DefaultProps.SingleLineText,
});
export default TabbedContent;
